import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import Feature from './Feature'

class Main extends React.Component {
  render(props) {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
        >
          <h2 className="major">The importance of our agency</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Nowadays, digital marketing is everything. Billboards are becoming
            less and less valuable, turning the internet into the most important
            way to send and receive information. Without a digital source, being
            ahead of big companies is nearly impossible. Giant Baoba exists to
            provide you the right support, assuring your work will be at its
            finest. By the way, check out our{' '}
            <a style={{ color: '#ce313a', fontWeight: 'bold' }} href="#work">
              features
            </a>
            .
          </p>
          <p>
            With the largest influencer network in the world, we will help you
            scale your brand to where you need it to be. We have helped pages
            grow from under 1,000 followers to over 1 million. Our world-class
            social strategy changes the game for your online presence.
          </p>
          <p>
            To be in the right path for a successful outcome, you need a proper
            team specialized in company growth. Without it, achieving a good
            result will be harder. Let us make it easier for you.
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              onClick={() => {
                props.onOpenArticle('contact')
              }}
            >
              Contact
            </button>
          </div>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Our Features</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <h4>
            A brief description of what you will achieve along with Giant
            Baoba’s services:
          </h4>
          <br />
          <Feature
            icon="fa-line-chart"
            title="Ascendency"
            text="Gain personal and professional development after
 implanting our strategies to your company. "
          />
          <Feature
            icon="fa-gratipay"
            title="Recognition"
            text="Achieving visibility is easier when the right team
is coordinating your services. Hard work is supposed to be
 valued and you will get there along with Giant Baoba. "
            side="right"
          />
          <Feature
            icon="fa-comments"
            title="Professional Support"
            text="Our team is composed of professional people working
 together to provide you support and orientation, helping you achieve your goal."
            side="left"
          />
          <Feature
            icon="fa-lightbulb-o"
            title="Creativity"
            text="Brand new ideas will be designed specially for your work.
 your clients will have the best first impression with
 no more generic tips retrieved from the internet."
            side="right"
          />
          <Feature
            icon="fa-globe"
            title="Entrance to Sucess"
            text="Achieving success is way more than performing a good presentation to your clients.
 With Giant Baoba, you can be sure that all these features descripted above
 are just the beginning to your company."
            side="left"
          />

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Abou Us</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            We are your full-service partner in Content Creation, Social Growth,
            Media Management, Design, Digital ads, and more. We implement a
            strategic and customized marketing plan optimized to convert leads,
            engage customers, and skyrocket success for your business. Our goal
            is to get you results and reduce your marketing headaches.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact Us</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://www.instagram.com/giantbaoba/"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
