import React from 'react'

export default function Feature({ icon, text, title, side }) {
  return (
    <div className="features">
      {side === 'right' ? (
        <div className={`icon ${icon}`}></div>
      ) : (
        <div
          style={{ backgroundColor: '#ce313a' }}
          className={`icon ${icon}`}
        ></div>
      )}
      <div>
        <h2 style={{ padding: 16 }}>{title}</h2>
        <p style={{ padding: 16 }}>{text}</p>
      </div>
    </div>
  )
}
