import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/logo.png'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <img src={logo} alt="logo" />
    </div>
    <div className="content">
      <div className="inner">
        <h1 style={{ color: '#ce313a', fontWeight: 'bold' }}>Giant Baoba</h1>
        <h3>Providing Visibility to your Company</h3>
        <p>
          Our specialty is to boost your business through a well-built design
          and digital marketing.
        </p>
        <button
          style={{ marginTop: '5%', borderRadius: 15, height: '5rem' }}
          onClick={() => {
            props.onOpenArticle('contact')
          }}
        >
          I want my company to be
          <p style={{ fontSize: '1rem' }}>successful!</p>
        </button>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            style={{ width: '100%' }}
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            style={{ width: '100%' }}
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            style={{ width: '100%' }}
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            style={{ width: '100%' }}
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
